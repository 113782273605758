@use '../../../../assets/css/deps' as *;

.address-list-container {
  max-width: none !important;
  margin: 10px 0 !important;
}

.address-list {
  padding: 4px 0;
  border-radius: 4px;
  background-color: var(--color-background-secondary);
}

.address {
  padding: 8px 12px;
  font-size: var(--button-1-font-size);
  border-radius: 0;
  cursor: pointer;
}

.address:nth-child(even) {
  background-color: var(--color-background-inverse);
}

.address:hover {
  background-color: var(--color-ui-primary-subtle);
}
