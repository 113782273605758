@use '../deps' as *;

.margin-left {
  margin-left: $app-padding;

  @include for-phone-only() {
    margin-left: $app-padding-mobile;
  }
}

.margin-right {
  margin-right: $app-padding;

  @include for-phone-only() {
    margin-right: $app-padding-mobile;
  }
}

.margin-top {
  margin-top: $app-padding;

  @include for-phone-only() {
    margin-top: $app-padding-mobile;
  }
}

.margin-bottom {
  margin-bottom: $app-padding;

  @include for-phone-only() {
    margin-bottom: $app-padding-mobile;
  }
}

.medium-margin-top {
  margin-top: $app-padding-mobile;
}

.medium-margin-bottom {
  margin-bottom: $app-padding-mobile;
}

.medium-margin-left {
  margin-left: $app-padding-mobile;
}

.medium-margin-right {
  margin-right: $app-padding-mobile;
}

.small-margin-top {
  margin-top: $app-padding-half;
}

.small-margin-bottom {
  margin-bottom: $app-padding-half;
}

.small-margin-left {
  margin-left: $app-padding-half;
}

.small-margin-right {
  margin-right: $app-padding-half;
}

.micro-margin-top {
  margin-top: 5px;
}

.micro-margin-bottom {
  margin-bottom: 5px;
}

.micro-margin-left {
  margin-left: 5px;
}

.micro-margin-right {
  margin-right: 5px;
}

.base-paddings {
  padding: $app-padding;

  @include for-phone-only() {
    padding: $app-padding-mobile;
  }

  &.no-top {
    padding-top: 0;
  }

  &.no-bottom {
    padding-bottom: 0.01px; // 0.01px fix for next block top margin
  }
}

.padding-left {
  padding-left: $app-padding;
}

.padding-right {
  padding-right: $app-padding;
}

.padding-top {
  padding-top: $app-padding;
}

.padding-bottom {
  padding-bottom: $app-padding;
}

.micro-padding-bottom {
  padding-bottom: 5px;
}

.medium-gap {
  gap: $app-padding;
}

.small-gap {
  gap: $app-padding-half;
}

.micro-gap {
  gap: 5px;
}
