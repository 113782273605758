@use '../../../../assets/css/deps' as *;

.header {
  &:global(.rmd-dialog__header) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 36px;
  }
}

.close {
  &:global(.rmd-button) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.group {
  --row-gap: #{$app-padding-half};
}

.model {
  padding: 8px 16px;
  grid-template-columns: auto auto 1fr;
  border: 1px solid var(--color-border-primary);
  border-radius: var(--shape-border-radius-base);
}

.file {
  flex: 1 1;
  height: 100%;
  justify-content: center !important;
}
