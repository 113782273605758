@use '../../deps' as *;

// label
.rmd-label {
  flex-direction: column;
  margin-left: 10px;
}

label.rmd-label--disabled {
  cursor: initial;
}

// text field, textarea, select
.rmd-text-field,
.rmd-textarea,
.rmd-select__value {
  font-size: var(--input-font-size);
}

.rmd-text-field {
  @include rmd-form-theme-update-var(text-padding-top, 1px);
}

.rmd-text-field,
.rmd-textarea {
  @include placeholder {
    color: var(--color-text-secondary);
    opacity: 1;
  }
}

.rmd-text-field-container--outline-right {
  $outline-offset: calc(#{rmd-icon-theme-var(size)} + #{rem($app-padding) * 2});

  @include rmd-form-theme-update-var(text-padding-right, $outline-offset);
}

.rmd-text-field-container--disabled {
  background-color: var(--color-background-disabled);
}

.rmd-text-field-container {
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  //transition: border-color 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.rmd-text-field-container--hoverable:hover {
  transition: none;
}

.rmd-select.rmd-text-field-container--hoverable:hover {
  color: var(--color-ui-primary);
}

//.rmd-text-field-container--outline::after {
//  transition: opacity 0.3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
//}
//.rmd-text-field-container--outline-active {
//  @include rmd-utils-keyboard-exclude() {
//    &:after {
//      content: initial;
//    }
//  }
//}

// textarea, auto height https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
.rmd-textarea-container {
  display: grid;
  padding-bottom: calc(#{rmd-form-theme-var(text-padding-top)} + #{rmd-form-theme-var(textarea-padding)});

  &:before {
    content: attr(data-replicated-value) ' ';
    padding-top: 2px;
    padding-bottom: 2px;

    @include rmd-utils-rtl {
      @include rmd-form-theme(padding-left, text-padding-right);
      @include rmd-form-theme(padding-right, text-padding-left);
    }

    @include rmd-form-theme(padding-left, text-padding-left);
    @include rmd-form-theme(padding-right, text-padding-right);
    @include rmd-form-theme(min-height, text-height);
    @include rmd-text-field-base;
    font-size: var(--input-font-size);
    height: 100%;
    white-space: pre-wrap;
    visibility: hidden;
    grid-area: 1 / 1 / 2 / 2;
  }
}

.rmd-textarea {
  overflow: hidden;
  resize: none;
  grid-area: 1 / 1 / 2 / 2;
}

// text field custom
.rmd-text-field-container--autogrow-addon {
  @include rmd-form-theme-update-var(text-padding-right, $app-padding);
}

.rmd-text-field-container--autosize {
  &:before {
    content: attr(data-replicated-value) ' ';
    display: inline-block;
    white-space: pre-wrap;
    visibility: hidden;
    font-size: var(--input-font-size);
    min-width: 80px;
    @include rmd-form-theme(padding-left, text-padding-left);
    @include rmd-form-theme(padding-right, text-padding-right);

    @include rmd-utils-rtl {
      @include rmd-form-theme(padding-left, text-padding-right);
      @include rmd-form-theme(padding-right, text-padding-left);
    }
  }

  .rmd-text-field {
    @include rmd-utils-full-screen(absolute);
    z-index: 0;
    text-align: center;
  }
}

.rmd-text-field-container--small {
  @include rmd-form-theme-update-var(text-height, rem(32));
  @include rmd-form-theme-update-var(text-padding-left, 8px);
  @include rmd-form-theme-update-var(text-padding-right, 8px);

  .rmd-text-field {
    font-size: var(--font-size-body-sm);
  }

  &.rmd-text-field-container--autosize {
    &:before {
      min-width: 30px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: var(--font-size-body-sm);
    }
  }
}

// select
.rmd-select.rmd-text-field-container--outline-active {
  border-radius: var(--shape-border-radius-base) var(--shape-border-radius-base) 0 0;

  @if $debug {
    background-color: $debug-color;
  }

  @include rmd-utils-keyboard-exclude() {
    &:after {
      content: initial;
    }
  }
}

//.rmd-select--disabled {
//  @include rmd-theme-update-var(text-disabled-on-background, text-primary-on-background);
//}

.rmd-text-field-container--inline {
  &.rmd-select {
    display: inline;
    border: none;

    @include rmd-utils-keyboard-exclude() {
      &:after {
        content: initial;
      }
    }

    @include rmd-form-theme-update-var(text-height, auto);
    @include rmd-form-theme-update-var(label-left-offset, 8px);
    @include rmd-form-theme-update-var(text-padding-left, 0);
    @include rmd-form-theme-update-var(text-padding-right, 20px);

    &:hover:not(.rmd-select--disabled) {
      cursor: pointer;
    }
  }

  &.rmd-text-field-container--disabled {
    @include rmd-form-theme-update-var(text-padding-right, 0);
    background-color: initial;
  }

  .rmd-select__value {
    font-size: inherit;
  }

  .rmd-text-field-addon:last-child {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.rmd-select.rmd-select--as-button {
  .rmd-select__value {
    font-size: var(--button-1-font-size);
    font-weight: 600;
  }
}

.rmd-listbox--temporary {
  border: 1px solid var(--color-border-primary);
  border-radius: 0 0 var(--shape-border-radius-base) var(--shape-border-radius-base);
  box-shadow: var(--shadow-ui-secondary);
  margin-top: -1px;
}

.rmd-text-field-addon {
  &:last-child {
    right: rem($app-padding);

    & > .rmd-icon {
      transition: transform 0.1s;
    }

    @at-root .rmd-select.rmd-text-field-container--outline-active & {
      & > .rmd-icon {
        transform: rotate(180deg);
      }
    }
  }
}

// listbox custom
.rmd-listbox--small {
  &.rmd-listbox--temporary {
    margin-top: 4px;
    border-radius: var(--shape-border-radius-base);
    @include rmd-list-theme-update-var(item-horizontal-padding, 12px);
    font-size: var(--font-size-body-sm);
  }
}

// checkbox, radio
.rmd-toggle {
  border-radius: var(--shape-border-radius-base);
  @include rmd-button-theme-update-var(icon-size, rem(30));

  @include rmd-utils-keyboard-exclude() {
    &:before {
      content: initial;
    }
  }

  &.rmd-toggle--focused,
  &:hover {
    .rmd-toggle__icon {
      &:before {
        border: 1px solid var(--color-ui-primary);
        transition: none;
      }
    }
  }
}

.rmd-toggle__input + .rmd-toggle__icon {
  position: static;

  &:before {
    @include rmd-utils-pseudo-element();
    border: 1px solid var(--color-border-primary);
    transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.1s ease-in;
  }

  .rmd-icon {
    position: relative;
    opacity: 0;
    fill: none;
    transition: opacity 0.1s ease-in;
    @include rmd-theme(color, primary);
  }
}

.rmd-toggle__input:checked + .rmd-toggle__icon {
  &:before {
    opacity: 1;
    //background-color: #F3F8FF;
    background-color: var(--color-ui-primary-subtlest);
    border: 1px solid var(--color-ui-primary);
  }

  .rmd-icon {
    opacity: 1;
  }
}

.rmd-toggle--radio {
  .rmd-toggle {
    @include rmd-button-theme-update-var(icon-size, rem(20));
  }

  .rmd-toggle__input + .rmd-toggle__icon {
    &:before {
      border-radius: 50%;
      @include rmd-theme(background-color, surface);
    }
  }

  & + .rmd-label {
    margin-left: 6px;
  }
}

.rmd-toggle--disabled {
  opacity: 0.6;
  pointer-events: none;
}

// file
.rmd-file-input-label {
}

// progress
.rmd-toggle--progress-submit {
  @include rmd-icon-theme-update-var(text-spacing, rem(10px));
}

// switch
.rmd-switch {
  border: 1px solid var(--color-border-primary);
}

.rmd-switch__ball {
  pointer-events: none;
  width: $rmd-switch-ball-size;
  height: $rmd-switch-ball-size;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);

  @include rmd-utils-keyboard-exclude() {
    &:before {
      content: initial;
    }
  }

  &:after {
    top: 0;
    left: 0;
    box-shadow: initial;
  }
}

.rmd-switch__input:checked + .rmd-switch__ball {
  transform: translate(0, -50%);
}
